import React from "react";
import {ReactComponent as TracksEmptySVG} from "../../../assets/img/splash/tracks-empty.svg";
import {ReactComponent as TracksEmptySVGShine} from "../../../assets/img/splash/tracks-empty.svg";

function TracksEmpty() {
  return (
    <div>
      <TracksEmptySVG className="tracks-empty" />
      <TracksEmptySVGShine className="tracks-empty-shine" />
    </div>
  );
}

export default TracksEmpty;
