import partnerezdorovya from '../../../assets/img/partner-ezdorovya.png'
import partneriqvia from '../../../assets/img/partner-iqvia.svg'
import partnerhelsi from '../../../assets/img/partner-helsi.svg'
import partnermedstar from '../../../assets/img/partner-medstar.svg'
import partnerelife from '../../../assets/img/partner-elife.png'
import partneraskep from '../../../assets/img/partner-askep.svg'
import partnerciet from '../../../assets/img/partner-ciet.png'
import partnerpfizer from '../../../assets/img/partner-pfizer.svg'
import partnermsd from '../../../assets/img/partner-msd.svg'
import partnerbayer from '../../../assets/img/partner-bayer.svg'
import partnerabbot from '../../../assets/img/partner-abbot.svg'
import partnerdarnitsya from '../../../assets/img/partner-darnitsya.svg'
import partnerfarmak from '../../../assets/img/partner-farmak.svg'
import partnerbiocodex from '../../../assets/img/partner-biocodex.svg'
import partnerdrreddys from '../../../assets/img/partner-drreddys.svg'
import partnerdeltamedical from '../../../assets/img/partner-deltamedical.svg'
import partnerkvz from '../../../assets/img/partner-kvz.png'
import partnerberlinchemie from '../../../assets/img/partner-berlinchemie.svg'
import partnernovaposhta from '../../../assets/img/partner-nova-poshta.svg'
import partnerarterium from '../../../assets/img/partner-arterium.png'
import partneralpenpharma from '../../../assets/img/partner-alpenpharma.png'
import partnermicrochim from '../../../assets/img/partner-microchim.png'
import partnerinchkyiv from '../../../assets/img/partner-inchkyiv.svg'
import '../../../assets/css/Partners.css'

export function Partners() {
    const year = new Date().getFullYear();

    return (<div id="partners" className="page">
            <div className="partners-container">
                <div className="partners-title">Our partners</div>
                <div className="partners-content">
                    <div className="partners-slider-top">
                        <ul className="partners-slider-top-items">
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnerezdorovya}
                                    className="highway-item-img partner-ezdorovya"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partneriqvia}
                                    className="logo-row-top-highway-item-img partner-iqvia"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnerhelsi}
                                    className="logo-row-top-highway-item-img partner-helsi"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnermedstar}
                                    className="logo-row-top-highway-item-img partner-medstar"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnerelife}
                                    className="logo-row-top-highway-item-img partner-elife"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partneraskep}
                                    className="logo-row-top-highway-item-img partner-askep"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                  alt=""
                                  src={partnerciet}
                                  className="logo-row-top-highway-item-img partner-ciet"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnerezdorovya}
                                    className="logo-row-top-highway-item-img partner-ezdorovya"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partneriqvia}
                                    className="logo-row-top-highway-item-img partner-iqvia"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnerhelsi}
                                    className="logo-row-top-highway-item-img partner-helsi"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnermedstar}
                                    className="logo-row-top-highway-item-img partner-medstar"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnerelife}
                                    className="logo-row-top-highway-item-img partner-elife"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partneraskep}
                                    className="logo-row-top-highway-item-img partner-askep"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                  alt=""
                                  src={partnerciet}
                                  className="logo-row-top-highway-item-img partner-ciet"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnerezdorovya}
                                    className="logo-row-top-highway-item-img partner-ezdorovya"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partneriqvia}
                                    className="logo-row-top-highway-item-img partner-iqvia"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnerhelsi}
                                    className="logo-row-top-highway-item-img partner-helsi"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnermedstar}
                                    className="logo-row-top-highway-item-img partner-medstar"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnerelife}
                                    className="logo-row-top-highway-item-img partner-elife"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partneraskep}
                                    className="logo-row-top-highway-item-img partner-askep"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnerciet}
                                    className="logo-row-top-highway-item-img partner-ciet"
                                />
                            </li>
                        </ul>
                    </div>
                    <div className="partners-slider-bottom">
                        <ul className="partners-slider-bottom-items">
                            <li className="partners-slider-bottom-item">
                                <img
                                    alt=""
                                    src={partnerpfizer}
                                    className="logo-row-bottom-highway-item-img partner-pfizer"
                                />
                            </li>
                            <li className="partners-slider-bottom-item">
                                <img
                                    alt=""
                                    src={partnermsd}
                                    className="logo-row-bottom-highway-item-img partner-msd"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnerbayer}
                                    className="logo-row-bottom-highway-item-img partner-bayer"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnerabbot}
                                    className="logo-row-bottom-highway-item-img partner-abbot"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnerdarnitsya}
                                    className="logo-row-bottom-highway-item-img partner-darnitsya"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnerfarmak}
                                    className="logo-row-bottom-highway-item-img partner-farmak"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnerbiocodex}
                                    className="logo-row-bottom-highway-item-img partner-biocodex"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnerdrreddys}
                                    className="logo-row-bottom-highway-item-img partner-drreddys"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnerdeltamedical}
                                    className="logo-row-bottom-highway-item-img partner-deltamedical"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnerkvz}
                                    className="logo-row-bottom-highway-item-img partner-kvz"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnerberlinchemie}
                                    className="logo-row-bottom-highway-item-img partner-berlinchemie"
                                />
                            </li>
                            <li className="partners-slider-bottom-item">
                                <img
                                    alt=""
                                    src={partnernovaposhta}
                                    className="logo-row-bottom-highway-item-img partner-novaposhta"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnerarterium}
                                    className="logo-row-bottom-highway-item-img partner-arterium"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partneralpenpharma}
                                    className="logo-row-bottom-highway-item-img partner-alpenpharma"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnermicrochim}
                                    className="logo-row-bottom-highway-item-img partner-microchim"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnerinchkyiv}
                                    className="logo-row-bottom-highway-item-img partner-partnerinchkyiv"
                                />
                            </li>
                            <li className="partners-slider-bottom-item">
                                <img
                                    alt=""
                                    src={partnerpfizer}
                                    className="logo-row-bottom-highway-item-img partner-pfizer"
                                />
                            </li>
                            <li className="partners-slider-bottom-item">
                                <img
                                    alt=""
                                    src={partnermsd}
                                    className="logo-row-bottom-highway-item-img partner-msd"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnerbayer}
                                    className="logo-row-bottom-highway-item-img partner-bayer"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnerabbot}
                                    className="logo-row-bottom-highway-item-img partner-abbot"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnerdarnitsya}
                                    className="logo-row-bottom-highway-item-img partner-darnitsya"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnerfarmak}
                                    className="logo-row-bottom-highway-item-img partner-farmak"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnerbiocodex}
                                    className="logo-row-bottom-highway-item-img partner-biocodex"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnerdrreddys}
                                    className="logo-row-bottom-highway-item-img partner-drreddys"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnerdeltamedical}
                                    className="logo-row-bottom-highway-item-img partner-deltamedical"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnerkvz}
                                    className="logo-row-bottom-highway-item-img partner-kvz"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnerberlinchemie}
                                    className="logo-row-bottom-highway-item-img partner-berlinchemie"
                                />
                            </li>
                            <li className="partners-slider-bottom-item">
                                <img
                                    alt=""
                                    src={partnernovaposhta}
                                    className="logo-row-bottom-highway-item-img partner-novaposhta"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnerarterium}
                                    className="logo-row-bottom-highway-item-img partner-arterium"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partneralpenpharma}
                                    className="logo-row-bottom-highway-item-img partner-alpenpharma"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnermicrochim}
                                    className="logo-row-bottom-highway-item-img partner-microchim"
                                />
                            </li>
                            <li className="partners-slider-top-item">
                                <img
                                    alt=""
                                    src={partnerinchkyiv}
                                    className="logo-row-bottom-highway-item-img partner-partnerinchkyiv"
                                />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="contacts">
                <div className="container-contacts">
                    <div className="contacts-title">Join us</div>
                    <div className="contacts-content">
                        <div className="contacts-subtitle">Kyiv Office</div>
                        <div className="contacts-text">
                            Oleksandra Myshuhy St, 10, Kyiv, 02141
                        </div>

                        <div className="contacts-text">
                            <a style={{color: '#fff'}} href={'mailto:hello@datum.llc'}>
                                hello@datum.llc
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer">
                <div className="footer-content">
                    <div className="footer-copyright">© Datum. {year}</div>
                </div>
            </div>
        </div>
    )
}
