import React, {useEffect, useState} from 'react';
import DatumChip from "./Chips/DatumChip";
import HealthcareChip from "./Chips/HealthcareChip";
import HealthcareEhealthChip from "./Chips/HealthcareEhealthChip";
import HealthcareBiotechChip from "./Chips/HealthcareBiotechChip";
import HealthcarePMChip from "./Chips/HealthcarePMChip";
import AquisitionChip from "./Chips/AquisitionChip";
import AquisitionLearningChip from "./Chips/AquisitionLearningChip";
import AquisitionMarketingChip from "./Chips/AquisitionMarketingChip";
import AquisitionCommunicationChip from "./Chips/AquisitionCommunicationChip";
import DataScienceChip from "./Chips/DataChip";
import DataDomainChip from "./Chips/DataDomainChip";
import DataBigDataChip from "./Chips/DataBigDataChip";
import DataAIChip from "./Chips/DataAIChip";
import EmptyChipsBottom from "./Chips/EmptyChipsBottom";
import EmptyChipsTop from "./Chips/EmptyChipsTop";
import TracksLeft from "./Chips/TracksLeft";
import TracksTop from "./Chips/TracksTop";
import TracksRight from "./Chips/TracksRight";
import TracksEmpty from "./Chips/TracksEmpty";
import DatumVideo from "./Chips/DatumVideo"
import '../../assets/css/Splash.css'

function Splash() {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setVisible(false)
        }, 5000)
    }, [])

    return (
        <div className={"splash-screen" + (visible ? '' : ' hidden')}>
            <div className="splash-container">
                <DatumChip/>

                <HealthcareChip/>
                <HealthcareEhealthChip/>
                <HealthcareBiotechChip/>
                <HealthcarePMChip/>

                <AquisitionChip/>
                <AquisitionLearningChip/>
                <AquisitionMarketingChip/>
                <AquisitionCommunicationChip/>

                <DataScienceChip/>
                <DataDomainChip/>
                <DataBigDataChip/>
                <DataAIChip/>

                <EmptyChipsBottom/>
                <EmptyChipsTop/>

                <TracksLeft/>
                <TracksTop/>
                <TracksRight/>
                <TracksEmpty/>

                <DatumVideo/>
            </div>
        </div>
    );
}

export default Splash;
