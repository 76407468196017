import React from 'react'

import '../../assets/js/scroll'

import {Hero, Solutions, Partners} from "./sections";
// import {Swiper, SwiperSlide} from "swiper/react";
// import {Pagination} from "swiper";

function Homepage() {
    return (
        <div className="homepage" id="homepage">
            {/*{window.outerWidth > 768 ?*/}
            {/*    <Swiper direction={'vertical'}*/}
            {/*            mousewheel={true}*/}
            {/*            pagination={{clickable: true}}*/}
            {/*            scrollbar={{draggable: true}}*/}
            {/*            modules={[Pagination]}*/}
            {/*            slidesPerView={1}>*/}
            {/*        <SwiperSlide>*/}
            {/*            <Hero/>*/}
            {/*        </SwiperSlide>*/}
            {/*        <SwiperSlide>*/}
            {/*            <Solutions/>*/}
            {/*        </SwiperSlide>*/}
            {/*        <SwiperSlide>*/}
            {/*            <Partners/>*/}
            {/*        </SwiperSlide>*/}
            {/*    </Swiper> : <React.Fragment>*/}
            <Hero/>
            <Solutions/>
            <Partners/>
            {/*</React.Fragment>}*/}
        </div>
    )
}

export default Homepage
