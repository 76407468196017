import '../../../assets/css/Hero.css'

import {useState} from "react";

export function Hero() {
    const [clicks, setClicks] = useState(0);

    const handleClick = () => {
        setClicks(clicks + 1)
    }

    return (
        <div className="page" id="hero">
            <div className="preloader">
                <svg
                    id="logo"
                    width="160"
                    height="53"
                    viewBox="0 0 160 53"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8 0H44.987C49.4053 0 52.987 3.58172 52.987 8V44.7318C52.987 49.1501 49.4053 52.7318 44.987 52.7318H8C3.58172 52.7318 0 49.1501 0 44.7318V8C0 3.58172 3.58172 0 8 0ZM35.2263 11.6667C34.3287 11.0632 33.3565 10.5694 32.3184 10.1854C30.2706 9.35329 28.1669 8.94178 26.0163 8.94178H12.1872C11.729 8.94178 11.3363 9.10639 10.9903 9.44472C10.6443 9.78302 10.476 10.1671 10.476 10.6151V14.0532C10.476 14.5287 10.6443 14.931 10.9903 15.2693C11.3363 15.6077 11.729 15.7723 12.1872 15.7723H25.8667C28.7653 15.7723 31.1309 16.8146 32.9543 18.8811C34.74 20.9568 35.6376 23.5445 35.6376 26.6717C35.6376 29.799 34.7495 32.3958 32.9543 34.4625C31.1309 36.5378 28.7653 37.5712 25.8667 37.5712H12.1872C11.729 37.5712 11.3363 37.7358 10.9903 38.0742C10.6443 38.4034 10.476 38.8148 10.476 39.2902V42.7282C10.476 43.1762 10.6443 43.5604 10.9903 43.8987C11.3363 44.2371 11.729 44.4017 12.1872 44.4017H26.0163C28.1669 44.4017 30.2706 43.9903 32.3184 43.1582C33.3656 42.7743 34.3287 42.2802 35.2263 41.6768C36.1239 41.0733 36.9469 40.3875 37.695 39.6194C41.1172 36.2453 42.828 31.9293 42.828 26.6717C42.828 21.4506 41.1172 17.1347 37.695 13.724C36.9469 12.9559 36.1239 12.2702 35.2263 11.6667Z"
                        fill="#D0D4DB"
                    />
                    <path
                        d="M77.4307 17.5968C77.9713 17.8031 78.4772 18.0684 78.9445 18.3926C79.4118 18.7168 79.8401 19.0853 80.2295 19.4979C82.0107 21.3303 82.9014 23.6489 82.9014 26.4539C82.9014 29.2786 82.0107 31.5973 80.2295 33.4101C79.8401 33.8227 79.4118 34.1912 78.9445 34.5152C78.4772 34.8395 77.9762 35.1045 77.4307 35.311C76.3649 35.758 75.2699 35.9792 74.1504 35.9792H66.9519C66.7133 35.9792 66.5088 35.8906 66.3285 35.7089C66.1485 35.527 66.0611 35.3208 66.0611 35.08V33.2331C66.0611 32.9778 66.1485 32.7566 66.3285 32.5797C66.5088 32.3979 66.7133 32.3095 66.9519 32.3095H74.0722C75.5815 32.3095 76.8129 31.7546 77.7618 30.6394C78.6961 29.529 79.1585 28.134 79.1585 26.4539C79.1585 24.7739 78.6915 23.3836 77.7618 22.2685C76.8129 21.1583 75.5815 20.5983 74.0722 20.5983H66.9519C66.7133 20.5983 66.5088 20.5099 66.3285 20.3281C66.1485 20.1463 66.0611 19.9302 66.0611 19.6748V17.8277C66.0611 17.587 66.1485 17.3807 66.3285 17.1989C66.5088 17.0171 66.7133 16.9287 66.9519 16.9287H74.1504C75.2699 16.9287 76.3649 17.1498 77.4307 17.5968Z"
                        fill="#D0D4DB"
                    />
                    <path
                        d="M102.645 35.5663C102.46 35.8415 102.212 35.9792 101.906 35.9792H84.9739C84.6529 35.9792 84.4045 35.8415 84.2344 35.5712C84.0495 35.3009 84.02 35.0211 84.1565 34.7311L84.9448 32.8695C85.0129 32.6976 85.1199 32.5648 85.2613 32.4619C85.4069 32.3585 85.5675 32.3095 85.757 32.3095H97.6047L96.8409 30.4574L93.435 22.2292L90.0291 30.4574C89.9607 30.6295 89.854 30.762 89.7127 30.8554C89.5671 30.9487 89.4015 30.9978 89.2166 30.9978H87.0856C86.7646 30.9978 86.5113 30.8603 86.3215 30.5852C86.1513 30.3296 86.1271 30.0448 86.2436 29.7353L91.4061 17.4937C91.4742 17.307 91.5809 17.1645 91.7222 17.0712C91.8682 16.9778 92.0289 16.9287 92.2187 16.9287H94.6608C95.0499 16.9287 95.3225 17.1154 95.4734 17.4937L102.718 34.7168C102.84 34.9916 102.81 35.2714 102.645 35.5663Z"
                        fill="#D0D4DB"
                    />
                    <path
                        d="M106.799 16.9287C107.052 16.9287 107.271 17.017 107.446 17.1986C107.621 17.3802 107.714 17.5961 107.714 17.8513V19.7209C107.714 19.9761 107.626 20.1969 107.446 20.3736C107.266 20.5552 107.052 20.6435 106.799 20.6435H104.184C103.945 20.6435 103.741 20.5552 103.561 20.3736C103.381 20.1921 103.294 19.9761 103.294 19.7209V17.8513C103.294 17.5961 103.381 17.3753 103.561 17.1986C103.741 17.0219 103.945 16.9287 104.184 16.9287H106.799ZM118.158 16.9287C118.396 16.9287 118.605 17.017 118.78 17.1986C118.96 17.3802 119.047 17.5961 119.047 17.8513V19.7209C119.047 19.9761 118.96 20.1969 118.78 20.3736C118.6 20.5552 118.396 20.6435 118.158 20.6435H113.052V35.2083C113.052 35.4488 112.965 35.6598 112.785 35.8366C112.605 36.0182 112.401 36.1062 112.162 36.1062H110.208C109.955 36.1062 109.736 36.0182 109.561 35.8366C109.382 35.6549 109.294 35.4488 109.294 35.2083V17.8513C109.294 17.5961 109.382 17.3753 109.561 17.1986C109.741 17.017 109.955 16.9287 110.208 16.9287H118.158Z"
                        fill="#D0D4DB"
                    />
                    <path
                        d="M128.033 32.3822C128.238 32.4509 128.398 32.5686 128.516 32.7304C128.632 32.8921 128.696 33.0786 128.696 33.2798V35.2515C128.696 35.526 128.584 35.7564 128.364 35.9429C128.194 36.08 128.009 36.1487 127.804 36.1487C127.721 36.1487 127.658 36.1389 127.624 36.1242C126.046 35.8153 124.687 35.0846 123.552 33.9222C122.027 32.3676 121.263 30.298 121.263 27.7182V17.8507C121.263 17.5957 121.35 17.375 121.53 17.1984C121.711 17.017 121.915 16.9287 122.154 16.9287H124.112C124.366 16.9287 124.584 17.017 124.76 17.1984C124.94 17.3799 125.028 17.5957 125.028 17.8507V27.7673C125.028 29.3906 125.486 30.6315 126.401 31.4847C126.879 31.9309 127.42 32.2255 128.033 32.3822ZM136.811 16.9287C137.064 16.9287 137.284 17.017 137.459 17.1984C137.639 17.3799 137.727 17.5957 137.727 17.8507V27.7182C137.727 30.298 136.962 32.3676 135.437 33.9222C134.302 35.0846 132.943 35.8202 131.365 36.1242C131.331 36.1438 131.273 36.1487 131.185 36.1487C130.98 36.1487 130.795 36.08 130.625 35.9429C130.405 35.7714 130.294 35.5309 130.294 35.2269V33.2553C130.294 33.0691 130.352 32.8921 130.473 32.7304C130.591 32.5686 130.751 32.4509 130.956 32.3822C131.599 32.196 132.145 31.8969 132.583 31.4847C133.499 30.6659 133.957 29.425 133.957 27.7673V17.8507C133.957 17.5957 134.045 17.375 134.225 17.1984C134.405 17.017 134.619 16.9287 134.872 16.9287H136.811Z"
                        fill="#D0D4DB"
                    />
                    <path
                        d="M159.114 16.9336C159.348 16.9336 159.557 17.0218 159.732 17.2033C159.908 17.3847 160 17.5906 160 17.8309V34.9969C160 35.2374 159.913 35.4432 159.732 35.6245C159.552 35.8061 159.348 35.8942 159.114 35.8942H157.197C156.944 35.8942 156.735 35.8061 156.564 35.6245C156.394 35.4432 156.311 35.2374 156.311 34.9969V23.4156L151.605 29.5151C151.42 29.7359 151.182 29.8486 150.895 29.8486C150.574 29.8486 150.335 29.7309 150.184 29.4907L141.843 18.3653C141.624 18.076 141.595 17.7671 141.765 17.4436C141.935 17.1003 142.193 16.9287 142.549 16.9287H144.772C145.06 16.9287 145.293 17.0464 145.478 17.2866L150.88 24.46L156.155 17.2866C156.34 17.0464 156.584 16.9287 156.891 16.9287H159.114V16.9336ZM144.466 29.3581C144.699 29.3581 144.909 29.4414 145.084 29.6132C145.259 29.7848 145.347 29.9956 145.347 30.2554V34.9969C145.347 35.2374 145.259 35.4432 145.084 35.6245C144.909 35.8061 144.699 35.8942 144.466 35.8942H142.549C142.31 35.8942 142.106 35.8061 141.93 35.6245C141.755 35.4432 141.668 35.2374 141.668 34.9969V30.2554C141.668 30.0006 141.755 29.7848 141.93 29.6132C142.106 29.4414 142.315 29.3581 142.549 29.3581H144.466Z"
                        fill="#D0D4DB"
                    />
                </svg>
                <h1 className="hero-moto">Infinite. Digital.&nbsp;<span onClick={handleClick}>Take</span>{clicks < 5 ?
                    <span>. Control.</span> : ' control.'}</h1>
            </div>
            <div id={'scroll-down'}>
            </div>
        </div>
    )
}
