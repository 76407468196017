import React from "react";
import {ReactComponent as EmptyChipsBottomSVG} from "../../../assets/img/splash/empty-chips-bottom.svg";

function EmptyChipsBottom() {
  return (
    <div>
      <EmptyChipsBottomSVG className="empty-chips-bottom" />
    </div>
  );
}

export default EmptyChipsBottom;
