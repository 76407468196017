import React from "react";
// import {ReactComponent as DatumChipOuterBox} from "../assets/img/splash/datum-chip-outer.svg";
import {ReactComponent as DatumChipInnerBox} from "../../../assets/img/splash/datum-chip.svg";
import {ReactComponent as DatumLogo} from "../../../assets/img/datum-logo-img.svg";

function DatumChip() {
  return (
    <div>
      {/*<DatumChipOuterBox className="datum-chip-outer" />*/}
      <DatumChipInnerBox className="datum-chip" />
      {/*<DatumLogo className="datum-logo-img" />*/}
    </div>
  );
}

export default DatumChip;
