import React from "react";
import {ReactComponent as TracksTopSVG} from "../../../assets/img/splash/tracks-top.svg";
import {ReactComponent as TracksTopSVGShine} from "../../../assets/img/splash/tracks-top.svg";

function TracksTop() {
  return (
    <div>
      <TracksTopSVGShine className="tracks-top-shine" />
      <TracksTopSVG className="tracks-top" />
    </div>
  );
}

export default TracksTop;
