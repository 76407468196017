import React from "react";
import {ReactComponent as AquisitionCommunicationChipSVG} from "../../../assets/img/splash/aquisition-communication-chip.svg";
// import {ReactComponent as AquisitionCommunicationChipOuterSVG} from "../assets/img/splash/aquisition-communication-chip-outer.svg";

function AquisitionCommunicationChip() {
  return (
    <div>
      <AquisitionCommunicationChipSVG className="aquisition-communication-chip" />
      {/*<AquisitionCommunicationChipOuterSVG className="aquisition-communication-chip-outer" />*/}
      <div className="aquisition-communication-chip-header">Communication</div>
    </div>
  );
}

export default AquisitionCommunicationChip;
