import React from "react";
import {ReactComponent as TracksLeftSVG} from "../../../assets/img/splash/tracks-left.svg";
import {ReactComponent as TracksLeftSVGShine} from "../../../assets/img/splash/tracks-left.svg";

function TracksLeft() {
  return (
    <div>
      <TracksLeftSVG className="tracks-left" />
      <TracksLeftSVGShine className="tracks-left-shine" />
    </div>
  );
}

export default TracksLeft;
