import React from "react";
import {ReactComponent as AquisitionLearningChipSVG} from "../../../assets/img/splash/aquisition-learning-chip.svg";
// import {ReactComponent as AquisitionLearningChipOuterSVG} from "../assets/img/splash/aquisition-learning-chip-outer.svg";

function AquisitionLearningChip() {
  return (
    <div>
      <AquisitionLearningChipSVG className="aquisition-learning-chip" />
      {/*<AquisitionLearningChipOuterSVG className="aquisition-learning-chip-outer" />*/}
      <div className="aquisition-learning-chip-header">Learning</div>
    </div>
  );
}

export default AquisitionLearningChip;
