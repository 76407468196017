import React from "react";
import {ReactComponent as DataBigDataChipSVG} from "../../../assets/img/splash/data-bigdata-chip.svg";
// import {ReactComponent as DataBigDataChipOuterSVG} from "../assets/img/splash/data-bigdata-chip-outer.svg";

function DataBigDataChip() {
  return (
    <div>
      <DataBigDataChipSVG className="data-big-data-chip" />
      {/*<DataBigDataChipOuterSVG className="data-big-data-chip-outer" />*/}
      <div className="data-big-data-chip-header">Big Data</div>
    </div>
  );
}

export default DataBigDataChip;
