import React from "react";
import logovideo from '../../../assets/img/datum-logo-video.mp4'
import {useRef, useEffect} from 'react';

function DatumVideo() {
    const ref = useRef(null);

    useEffect(() => {
        const el = document.getElementById('videobackground');
        el.pause();
        setTimeout(() => {
            el.play();
        }, 2700)
    }, []);

    return (
        <div className="datum-logo-video">
            <video id="videobackground" width="155px" height="82px" autoPlay muted preload="true">
                <source src={logovideo} type="video/mp4"/>
            </video>
        </div>
    );
}

export default DatumVideo;
