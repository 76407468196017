import React from "react";
import {ReactComponent as AquisitionChipSVG} from "../../../assets/img/splash/aquisition-chip.svg";
// import {ReactComponent as AquisitionChipOuterSVG} from "../assets/img/splash/aquisition-chip-outer.svg";

function AquisitionChip() {
  return (
    <div>
      <AquisitionChipSVG className="aquisition-chip" />
      {/*<AquisitionChipOuterSVG className="aquisition-chip-outer" />*/}
      <div className="aquisition-chip-header">Digital Aquisition</div>
    </div>
  );
}

export default AquisitionChip;
