import React from "react";
import {ReactComponent as AquisitionMarketingChipSVG} from "../../../assets/img/splash/aquisition-marketing-chip.svg";
// import {ReactComponent as AquisitionMarketingChipOuterSVG} from "../assets/img/splash/aquisition-marketing-chip-outer.svg";

function AquisitionMarketingChip() {
  return (
    <div>
      <AquisitionMarketingChipSVG className="aquisition-marketing-chip" />
      {/*<AquisitionMarketingChipOuterSVG className="aquisition-marketing-chip-outer" />*/}
      <div className="aquisition-marketing-chip-header">Marketing</div>
    </div>
  );
}

export default AquisitionMarketingChip;
