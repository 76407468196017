let pages = [];
let state = {
    wheeling: false,
    functionCall: false
}
let scrollTimer = false;
let activeIndex = 0;
let activeSection;
let sections = [];

const duration = 1000;

function doScrolling(elementY, duration) {
    var startingY = window.pageYOffset;
    var diff = elementY - startingY;
    var start;

    // Bootstrap our animation - it will get called right before next frame shall be rendered.
    window.requestAnimationFrame(function step(timestamp) {
        if (!start) start = timestamp;
        // Elapsed milliseconds since start of wheeling.
        var time = timestamp - start;
        // Get percent of completion in range [0, 1].
        var percent = Math.min(time / duration, 1);

        window.scrollTo(0, startingY + diff * percent);

        // Proceed with animation as long as we wanted it to.
        if (time < duration) {
            window.requestAnimationFrame(step);
        }
    })
}

function checkScrollDirectionIsUp(event) {
    if (event.wheelDelta) {
        return event.wheelDelta > 0;
    }
    return event.deltaY < 0;
}


function onLoad() {
    pages = document.getElementsByClassName("page");

    for (let page of pages) {
        sections.push(page.id)
    }

    activeSection = sections[0];

    function scroll(event) {
        if (checkScrollDirectionIsUp(event) && activeIndex > 0) {
            activeIndex--;
        } else if (!checkScrollDirectionIsUp(event) && activeIndex < sections.length - 1) {
            activeIndex++;
        } else {
            return false;
        }

        activeSection = sections[activeIndex];

        const element = document.getElementById(activeSection)

        if (activeSection) {
            doScrolling(element.offsetTop, duration)
        }
    }

    document.addEventListener('wheel', (event) => {
        state.wheeling = true;
        if (!state.functionCall) {
            scroll(event);
            state.functionCall = true;
        }
        window.clearInterval(scrollTimer);
        scrollTimer = window.setTimeout(function () {
            state.wheeling = false;
            state.functionCall = false;
        }, 200);
    })
}

document.addEventListener("DOMContentLoaded", () => {
    if (window.outerWidth > 768) {
        setTimeout(() => {
            window.scrollTo(0, 0)
            onLoad();
        }, 5000)
    }
});
