import React from "react";
import {ReactComponent as EmptyChipsTopSVG} from "../../../assets/img/splash/empty-chips-top.svg";

function EmptyChipsTop() {
  return (
    <div>
      <EmptyChipsTopSVG className="empty-chips-top" />
    </div>
  );
}

export default EmptyChipsTop;
