import './assets/css/App.css'
import Splash from './components/Splash/Splash'
import Homepage from './pages/home/Homepage'

function App() {
    return (
        <div className="App">
            {window.outerWidth > 768 && <Splash/>}
            <Homepage/>
        </div>
    )
}

export default App
