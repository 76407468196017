import React from "react";
import {ReactComponent as DataDomainChipSVG} from "../../../assets/img/splash/data-domain-chip.svg";
// import {ReactComponent as DataDomainChipOuterSVG} from "../assets/img/splash/data-domain-chip-outer.svg";

function DataDomainChip() {
  return (
    <div>
      <DataDomainChipSVG className="data-domain-chip" />
      {/*<DataDomainChipOuterSVG className="data-domain-chip" />*/}
      <div className="data-domain-chip-header">Domain Expertise</div>
    </div>
  );
}

export default DataDomainChip;
