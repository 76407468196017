import React from "react";
import {ReactComponent as HealthcareEhealthChipSVG} from "../../../assets/img/splash/healthcare-ehealth-chip.svg";
// import {ReactComponent as HealthcareEhealthChipOuterSVG} from "../assets/img/splash/healthcare-ehealth-chip-outer.svg";

function HealthcareEhealthChip() {
  return (
    <div>
      <HealthcareEhealthChipSVG className="healthcare-ehealth-chip" />
      {/*<HealthcareEhealthChipOuterSVG className="healthcare-ehealth-chip-outer" />*/}
      <div className="healthcare-ehealth-chip-header">E-health</div>
    </div>
  );
}

export default HealthcareEhealthChip;
