import React from "react";
import {ReactComponent as TracksRightSVG} from "../../../assets/img/splash/tracks-right.svg";
import {ReactComponent as TracksRightSVGShine} from "../../../assets/img/splash/tracks-right.svg";

function TracksRight() {
  return (
    <div>
      <TracksRightSVGShine className="tracks-right-shine" />
      <TracksRightSVG className="tracks-right" />
    </div>
  );
}

export default TracksRight;
