import React from 'react';

import '../../../../assets/css/Solutions.css'

import {Swiper, SwiperSlide} from "swiper/react";

import 'swiper/css'
import {Healthcare, DigitalAcquisition, DataScience} from "./Slides";

export function Solutions() {
    return (
        <div id="solutions" className="page">
            <div className="solutions-title">Our Capacities</div>
            <div className="solutions-items">
                {window.outerWidth > 768 ? <React.Fragment>
                        <Healthcare/>
                        <DigitalAcquisition/>
                        <DataScience/>
                    </React.Fragment> :
                    <Swiper spaceBetween={40}
                            slidesPerView={1}
                            pagination={{clickable: true}}>
                        <SwiperSlide>
                            <Healthcare/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <DigitalAcquisition/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <DataScience/>
                        </SwiperSlide>
                    </Swiper>}
            </div>
        </div>
    )
}
