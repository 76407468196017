export function Healthcare(){
    return (
        <div className="solutions-item healthcare-box">
            <div className="solutions-item-title">
                Healthcare & Life Sciences
            </div>
            <div className="solutions-item-img">
                <svg
                    className="healthcare-shine"
                    width="114"
                    height="151"
                    viewBox="0 0 114 151"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M73.6392 51.5241C70.8939 52.2839 68.0616 50.6719 67.313 47.9235C66.5645 45.1751 68.1832 42.3311 70.9285 41.5713C73.6738 40.8115 76.5061 42.4235 77.2546 45.1719C78.0032 47.9203 76.3845 50.7643 73.6392 51.5241Z"
                        fill="#888888"
                    />
                    <path
                        d="M64.4953 51.6456C63.2043 53.2497 60.8637 53.5059 59.2675 52.2178C57.6713 50.9297 57.424 48.5851 58.715 46.981C60.006 45.3769 62.3466 45.1207 63.9428 46.4088C65.539 47.6969 65.7863 50.0415 64.4953 51.6456Z"
                        fill="#424242"
                    />
                    <path
                        d="M54.2479 56.5308C52.9202 57.9605 50.691 58.0451 49.269 56.7196C47.8469 55.3942 47.7704 53.1607 49.0981 51.7309C50.4258 50.3012 52.6549 50.2166 54.077 51.5421C55.499 52.8675 55.5756 55.101 54.2479 56.5308Z"
                        fill="#424242"
                    />
                    <path
                        d="M45.1346 62.9762C44.0811 64.1107 42.3122 64.1779 41.1837 63.1261C40.0553 62.0743 39.9945 60.3019 41.0481 59.1674C42.1017 58.0328 43.8705 57.9657 44.999 59.0175C46.1275 60.0693 46.1882 61.8416 45.1346 62.9762Z"
                        fill="#424242"
                    />
                    <path
                        d="M36.2147 72.4722C34.8285 72.3004 33.8476 71.0346 34.0239 69.6448C34.2002 68.2551 35.4669 67.2677 36.8531 67.4395C38.2393 67.6112 39.2202 68.8771 39.0439 70.2668C38.8676 71.6566 37.6009 72.6439 36.2147 72.4722Z"
                        fill="#424242"
                    />
                    <path
                        d="M34.5357 81.3856C33.8988 82.5046 32.4791 82.8975 31.3647 82.2632C30.2503 81.629 29.8632 80.2077 30.5001 79.0887C31.137 77.9697 32.5567 77.5767 33.6711 78.211C34.7855 78.8453 35.1726 80.2666 34.5357 81.3856Z"
                        fill="#424242"
                    />
                    <path
                        d="M33.2318 92.329C32.6069 93.427 31.2139 93.8125 30.1205 93.1902C29.027 92.5678 28.6472 91.1732 29.2721 90.0753C29.897 88.9773 31.29 88.5918 32.3835 89.2142C33.4769 89.8365 33.8567 91.2311 33.2318 92.329Z"
                        fill="#424242"
                    />
                    <path
                        d="M33.8784 103.011C33.4149 103.825 32.3819 104.111 31.571 103.649C30.76 103.188 30.4784 102.154 30.9418 101.339C31.4052 100.525 32.4383 100.239 33.2492 100.701C34.0602 101.162 34.3418 102.197 33.8784 103.011Z"
                        fill="#424242"
                    />
                    <path
                        d="M36.483 113.173C36.1532 113.752 35.4181 113.956 34.8411 113.627C34.264 113.299 34.0636 112.563 34.3934 111.984C34.7231 111.404 35.4583 111.201 36.0353 111.529C36.6123 111.858 36.8128 112.593 36.483 113.173Z"
                        fill="#424242"
                    />
                    <path
                        d="M83.9975 47.5896C81.8984 48.1705 79.7327 46.9379 79.1604 44.8364C78.588 42.735 79.8257 40.5604 81.9248 39.9794C84.0239 39.3985 86.1896 40.6311 86.7619 42.7325C87.3343 44.834 86.0966 47.0086 83.9975 47.5896Z"
                        fill="#424242"
                    />
                    <path
                        d="M95.9032 40.6779C94.9518 42.3496 92.8308 42.9367 91.1659 41.9891C89.501 41.0415 88.9227 38.9181 89.8742 37.2464C90.8257 35.5747 92.9467 34.9877 94.6116 35.9352C96.2764 36.8828 96.8547 39.0062 95.9032 40.6779Z"
                        fill="#424242"
                    />
                    <path
                        d="M101.105 34.8936C99.5243 34.6976 98.4055 33.2538 98.6066 31.6685C98.8077 30.0833 100.253 28.9571 101.834 29.153C103.415 29.3489 104.534 30.7928 104.333 32.378C104.132 33.9632 102.687 35.0895 101.105 34.8936Z"
                        fill="#424242"
                    />
                    <path
                        d="M110.202 24.3787C109.517 25.5826 107.989 26.0054 106.79 25.323C105.591 24.6405 105.175 23.1114 105.86 21.9075C106.545 20.7036 108.073 20.2808 109.272 20.9632C110.471 21.6456 110.887 23.1748 110.202 24.3787Z"
                        fill="#424242"
                    />
                    <path
                        d="M40.0057 146.309C39.1353 147.394 37.5543 147.57 36.4745 146.701C35.3948 145.833 35.2251 144.249 36.0956 143.164C36.966 142.079 38.547 141.903 39.6268 142.772C40.7066 143.64 40.8762 145.224 40.0057 146.309Z"
                        fill="#424242"
                    />
                    <path
                        d="M41.0506 135.259C40.2344 136.223 38.7951 136.344 37.836 135.529C36.8768 134.715 36.761 133.273 37.5772 132.309C38.3934 131.345 39.8327 131.224 40.7918 132.038C41.751 132.853 41.8668 134.295 41.0506 135.259Z"
                        fill="#424242"
                    />
                    <path
                        d="M39.6282 123.643C39.1648 124.457 38.1317 124.743 37.3208 124.281C36.5099 123.82 36.2282 122.786 36.6916 121.971C37.1551 121.157 38.1881 120.871 38.9991 121.333C39.81 121.794 40.0916 122.829 39.6282 123.643Z"
                        fill="#424242"
                    />
                    <path
                        d="M37.1237 117.987C34.2179 118.791 31.22 117.085 30.4277 114.175C29.6354 111.266 31.3487 108.256 34.2545 107.452C37.1604 106.648 40.1583 108.354 40.9506 111.263C41.7429 114.172 40.0296 117.182 37.1237 117.987Z"
                        fill="#888888"
                    />
                    <path
                        d="M6.21199 127.771C8.89963 129.124 11.5593 130.527 14.21 131.947C15.538 132.652 16.8552 133.377 18.1771 134.091L22.1287 136.265L26.0648 138.466C27.3716 139.208 28.6834 139.942 29.9854 140.695C32.5941 142.191 35.1921 143.704 37.7628 145.268L38.3426 144.222C35.6549 142.87 32.9949 141.468 30.3446 140.047C29.0166 139.341 27.7001 138.617 26.3774 137.902L22.4258 135.729L18.4897 133.528C17.183 132.785 15.8712 132.051 14.5692 131.298C11.9605 129.803 9.36248 128.29 6.79178 126.726L6.21199 127.771Z"
                        fill="#424242"
                    />
                    <path
                        d="M14.793 120.829C16.8759 121.838 18.9292 122.9 20.974 123.978L22.5055 124.789L24.0305 125.612C25.0497 126.157 26.0586 126.719 27.0717 127.274C29.0967 128.388 31.1055 129.529 33.1032 130.692C35.098 131.859 37.0818 133.047 39.0276 134.303L39.6088 133.258C37.5151 132.268 35.4601 131.21 33.4156 130.132C31.3739 129.049 29.3446 127.945 27.3297 126.813C26.3228 126.247 25.3125 125.686 24.3122 125.108L22.8083 124.246L21.3109 123.373C19.317 122.205 17.3304 121.022 15.3745 119.785L14.793 120.829Z"
                        fill="#424242"
                    />
                    <path
                        d="M24.7205 116.005C25.8895 116.484 27.0224 117.028 28.1495 117.582C29.2743 118.141 30.3835 118.726 31.4838 119.329C32.5841 119.932 33.6713 120.556 34.7397 121.216C35.8059 121.879 36.8608 122.563 37.8695 123.33L38.4513 122.286C37.2684 121.831 36.1319 121.295 35.0071 120.737C33.8839 120.176 32.7798 119.58 31.6891 118.962C30.5973 118.344 29.5152 117.709 28.449 117.047C27.3851 116.38 26.326 115.703 25.3034 114.961L24.7205 116.005Z"
                        fill="#424242"
                    />
                    <path
                        d="M36.2142 70.59C38.2013 71.4927 40.1518 72.4599 42.0912 73.4474C44.0325 74.4318 45.9536 75.4515 47.8687 76.4829C48.8273 76.9965 49.7755 77.5287 50.7291 78.0512C51.6759 78.5858 52.6254 79.1156 53.5653 79.6623C55.4488 80.7492 57.3175 81.863 59.1464 83.0469L59.8393 81.812C57.8462 80.9198 55.8925 79.9583 53.9544 78.9683C52.9838 78.4767 52.0219 77.9687 51.0572 77.4655C50.0999 76.9494 49.1371 76.4441 48.1848 75.9192C46.2775 74.8741 44.3766 73.8178 42.4959 72.726C40.6134 71.6375 38.7424 70.5278 36.9071 69.3551L36.2142 70.59Z"
                        fill="#424242"
                    />
                    <path
                        d="M43.5559 87.004C45.363 87.8969 47.1476 88.8303 48.9235 89.779L49.5901 90.1339L50.2542 90.4933L51.582 91.2125L54.2267 92.6704L56.8598 94.1495C57.7339 94.6492 58.6111 95.1435 59.4808 95.651C61.2238 96.6595 62.9592 97.6815 64.6727 98.7429L65.0234 98.1103C63.2174 97.2154 61.4335 96.2807 59.6574 95.3325C58.7673 94.8618 57.8845 94.3774 56.9989 93.8989L54.3531 92.4431L51.7187 90.9661L50.4069 90.2178L49.7513 89.8436L49.0982 89.4648C47.3549 88.4568 45.6201 87.4339 43.9075 86.3698L43.5559 87.004Z"
                        fill="#424242"
                    />
                    <path
                        d="M30.962 91.7244C33.1201 92.7573 35.244 93.8517 37.3559 94.9673C38.4153 95.5189 39.4612 96.0944 40.5141 96.6576L43.6528 98.3837L46.7729 100.142C47.8074 100.739 48.8467 101.326 49.8747 101.935C51.9361 103.141 53.9859 104.369 56.0026 105.656L56.5838 104.611C54.4265 103.577 52.3026 102.483 50.1911 101.366C49.132 100.814 48.085 100.241 47.0322 99.6757L43.8924 97.9517L40.7711 96.195C39.7369 95.5973 38.6968 95.0117 37.6693 94.4025C35.6078 93.1968 33.5581 91.9689 31.5425 90.6793L30.962 91.7244Z"
                        fill="#424242"
                    />
                    <path
                        d="M32.1194 102.697C33.357 103.211 34.5574 103.793 35.7485 104.39C36.9372 104.992 38.1127 105.619 39.275 106.269C40.4378 106.918 41.5881 107.589 42.7248 108.284C43.8596 108.984 44.9851 109.698 46.0744 110.479L46.6569 109.435C45.4201 108.92 44.2205 108.337 43.029 107.74C41.8403 107.138 40.6648 106.511 39.5013 105.863C38.3374 105.216 37.1867 104.546 36.0503 103.851C34.9159 103.152 33.7901 102.436 32.7019 101.653L32.1194 102.697Z"
                        fill="#424242"
                    />
                    <path
                        d="M56.5954 69.955C58.2477 70.6316 59.8559 71.3866 61.4505 72.1655C63.047 72.9411 64.619 73.7606 66.1836 74.5933C66.9673 75.0066 67.7378 75.4441 68.5158 75.8684L69.6699 76.5274C70.0539 76.7482 70.4399 76.9657 70.8188 77.1956C72.3453 78.096 73.8525 79.0302 75.3127 80.0489L76.0224 78.7876C74.3625 78.1242 72.7506 77.3758 71.1579 76.5935C70.7569 76.4024 70.3629 76.2 69.9671 75.9998L68.7815 75.3969C67.9993 74.9801 67.2104 74.5758 66.4343 74.1482C64.8792 73.2991 63.3316 72.4357 61.8089 71.5287C60.2838 70.6258 58.7733 69.6973 57.306 68.6921L56.5954 69.955Z"
                        fill="#424242"
                    />
                    <path
                        d="M51.3873 54.6574C53.5462 55.6888 55.6712 56.7812 57.7866 57.8907L59.3717 58.7262L60.9489 59.5753C62.0026 60.1371 63.0468 60.717 64.0931 61.2918C66.1886 62.4377 68.2609 63.6244 70.3227 64.8294L71.8639 65.7436L73.3966 66.6727C74.4141 67.2995 75.4304 67.9284 76.4289 68.5904L77.01 67.5457C75.9219 67.0462 74.8512 66.5142 73.7817 65.9803L72.1846 65.1676L70.5959 64.34C68.4848 63.2231 66.3835 62.0874 64.305 60.9116C63.2649 60.326 62.2215 59.7447 61.1885 59.145L59.6359 58.2524L58.0905 57.3457C56.0322 56.1328 53.9843 54.9032 51.9692 53.6131L51.3873 54.6574Z"
                        fill="#424242"
                    />
                    <path
                        d="M61.3152 49.8331C61.9373 50.0845 62.5458 50.3597 63.1527 50.6394C63.759 50.9186 64.3605 51.2078 64.9566 51.5048C66.1512 52.0965 67.3298 52.7177 68.4972 53.357C69.6661 53.9953 70.8207 54.6587 71.9536 55.3603C73.0849 56.0663 74.2027 56.7944 75.2698 57.6162L75.8523 56.5722C74.593 56.096 73.3853 55.5275 72.1903 54.9365C70.9981 54.3408 69.8265 53.7073 68.6692 53.0486C67.5115 52.3906 66.3639 51.7149 65.2322 51.0096C64.666 50.6584 64.1037 50.2988 63.5479 49.9293C62.991 49.5601 62.4376 49.1865 61.8966 48.7894L61.3152 49.8331Z"
                        fill="#424242"
                    />
                    <path
                        d="M69.5211 36.9838C70.704 37.4382 71.8374 37.9797 72.961 38.54C74.0823 39.1043 75.1834 39.7058 76.2736 40.3263C77.3646 40.9454 78.4451 41.5829 79.5136 42.2415C80.5794 42.9049 81.6409 43.5775 82.6698 44.3073L83.2516 43.263C82.0892 42.7725 80.959 42.2238 79.8335 41.667C78.7106 41.1053 77.6002 40.522 76.4991 39.9205C75.3973 39.3204 74.3074 38.7009 73.2366 38.0447C72.1689 37.3847 71.1113 36.7053 70.1022 35.9391L69.5211 36.9838Z"
                        fill="#424242"
                    />
                    <path
                        d="M68.3632 26.012C70.4476 27.0182 72.5009 28.0806 74.5423 29.1641C75.5654 29.7023 76.5782 30.2571 77.5963 30.8042C78.6078 31.3628 79.6225 31.9142 80.6309 32.4783C82.6478 33.6064 84.6596 34.7433 86.6551 35.9088C88.6518 37.0723 90.6399 38.2525 92.5985 39.4844L93.1797 38.4397C91.0995 37.426 89.0477 36.3609 87.0056 35.2787C84.962 34.1993 82.935 33.0907 80.912 31.9735C79.9004 31.4148 78.8959 30.844 77.8875 30.2799C76.8856 29.7043 75.8795 29.1362 74.8827 28.5517C72.8849 27.3903 70.8987 26.2067 68.9443 24.9673L68.3632 26.012Z"
                        fill="#424242"
                    />
                    <path
                        d="M69.6302 15.0466C72.3356 16.3676 75.0046 17.7542 77.6612 19.1627C78.9924 19.8609 80.3111 20.5827 81.6357 21.2942L85.5904 23.4623L89.5249 25.6656L91.4833 26.783L92.4623 27.342L93.437 27.9085C96.0396 29.415 98.6282 30.946 101.18 32.5434L101.76 31.4979C99.0531 30.179 96.384 28.7941 93.7272 27.3845L92.73 26.8575L91.737 26.323L89.7516 25.2533L85.7984 23.0825L81.8654 20.8764C80.5606 20.1302 79.2504 19.3935 77.9523 18.6339C75.3501 17.1267 72.7607 15.5971 70.2078 14.0017L69.6302 15.0466Z"
                        fill="#424242"
                    />
                    <path
                        d="M73.648 4.76421C76.5534 6.22417 79.4281 7.73787 82.2927 9.27102C85.1592 10.8008 88.0075 12.3625 90.8516 13.9317L95.1069 16.3059C96.5179 17.1099 97.9362 17.9027 99.3421 18.7156C102.16 20.3321 104.967 21.9701 107.741 23.6659L108.32 22.6205C105.413 21.1663 102.536 19.6536 99.6722 18.1198C98.2372 17.358 96.8142 16.5751 95.3843 15.8044L91.1164 13.4537C88.2783 11.8725 85.4451 10.2843 82.6294 8.66368C79.8118 7.04647 77.0039 5.41051 74.2274 3.71943L73.648 4.76421Z"
                        fill="#424242"
                    />
                    <path
                        d="M76.4469 5.66895C75.6549 7.06036 73.8896 7.54895 72.5039 6.76025C71.1182 5.97156 70.6368 4.20423 71.4288 2.81282C72.2207 1.42142 73.9861 0.932821 75.3718 1.72152C76.7575 2.51022 77.2388 4.27754 76.4469 5.66895Z"
                        fill="#888888"
                    />
                    <path
                        d="M71.7507 16.2335C70.8042 17.2527 69.215 17.313 68.2012 16.3681C67.1875 15.4232 67.1329 13.831 68.0794 12.8117C69.0259 11.7925 70.615 11.7322 71.6288 12.6771C72.6426 13.622 72.6972 15.2142 71.7507 16.2335Z"
                        fill="#888888"
                    />
                    <path
                        d="M70.3154 27.0407C69.456 27.9662 68.013 28.0209 67.0925 27.1629C66.172 26.305 66.1224 24.8592 66.9819 23.9336C67.8413 23.0081 69.2843 22.9534 70.2048 23.8114C71.1253 24.6694 71.1749 26.1152 70.3154 27.0407Z"
                        fill="#888888"
                    />
                    <path
                        d="M71.2801 37.2977C70.8166 38.1119 69.7835 38.3978 68.9726 37.9363C68.1617 37.4747 67.88 36.4405 68.3435 35.6263C68.8069 34.812 69.84 34.5261 70.6509 34.9876C71.4618 35.4492 71.7435 36.4834 71.2801 37.2977Z"
                        fill="#888888"
                    />
                    <path
                        d="M28.2946 117.352C27.2583 119.173 24.948 119.813 23.1346 118.781C21.3212 117.749 20.6912 115.436 21.7276 113.615C22.764 111.794 25.0743 111.154 26.8877 112.187C28.7011 113.219 29.331 115.532 28.2946 117.352Z"
                        fill="#888888"
                    />
                    <path
                        d="M18.2168 122.091C17.2277 123.828 15.0228 124.439 13.2921 123.454C11.5613 122.469 10.9601 120.261 11.9493 118.523C12.9384 116.785 15.1433 116.175 16.874 117.16C18.6047 118.145 19.2059 120.353 18.2168 122.091Z"
                        fill="#888888"
                    />
                    <path
                        d="M8.58143 129.173C7.51999 130.325 5.73101 130.399 4.58562 129.34C3.44023 128.28 3.37218 126.488 4.43362 125.336C5.49506 124.184 7.28405 124.11 8.42944 125.17C9.57482 126.229 9.64287 128.022 8.58143 129.173Z"
                        fill="#888888"
                    />
                    <path
                        d="M47.3556 113.589C45.3517 114.143 43.2843 112.967 42.7379 110.96C42.1915 108.954 43.3731 106.878 45.377 106.324C47.3808 105.769 49.4482 106.946 49.9946 108.952C50.541 110.958 49.3595 113.034 47.3556 113.589Z"
                        fill="#888888"
                    />
                    <path
                        d="M59.1912 106.784C58.2765 108.391 56.2376 108.955 54.6371 108.044C53.0365 107.133 52.4806 105.092 53.3953 103.485C54.31 101.878 56.349 101.313 57.9495 102.224C59.55 103.135 60.1059 105.176 59.1912 106.784Z"
                        fill="#888888"
                    />
                    <path
                        d="M64.5168 100.997C62.9732 100.806 61.8811 99.3966 62.0774 97.8491C62.2737 96.3016 63.6841 95.2022 65.2277 95.3934C66.7713 95.5847 67.8634 96.9942 67.6671 98.5417C67.4708 100.089 66.0604 101.189 64.5168 100.997Z"
                        fill="#888888"
                    />
                    <path
                        d="M72.111 91.7917C70.7442 92.17 69.334 91.3674 68.9613 89.999C68.5886 88.6307 69.3946 87.2147 70.7614 86.8364C72.1282 86.4581 73.5384 87.2607 73.9111 88.6291C74.2838 89.9975 73.4778 91.4134 72.111 91.7917Z"
                        fill="#888888"
                    />
                    <path
                        d="M77.6344 80.273C76.9461 81.4822 75.412 81.9068 74.2078 81.2214C73.0036 80.536 72.5853 79.0001 73.2735 77.791C73.9617 76.5818 75.4959 76.1572 76.7001 76.8426C77.9043 77.528 78.3226 79.0638 77.6344 80.273Z"
                        fill="#888888"
                    />
                    <path
                        d="M78.6996 69.1937C78.0746 70.2917 76.6816 70.6772 75.5882 70.0548C74.4947 69.4325 74.1149 68.0379 74.7398 66.94C75.3647 65.842 76.7577 65.4565 77.8512 66.0788C78.9446 66.7012 79.3245 68.0958 78.6996 69.1937Z"
                        fill="#888888"
                    />
                    <path
                        d="M77.0298 57.9297C76.5664 58.7439 75.5333 59.0299 74.7224 58.5683C73.9115 58.1068 73.6298 57.0725 74.0932 56.2583C74.5567 55.444 75.5898 55.1581 76.4007 55.6196C77.2116 56.0812 77.4933 57.1154 77.0298 57.9297Z"
                        fill="#888888"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M59.066 85.7312C60.2328 86.8188 62.0619 86.7494 63.1513 85.5763C64.2407 84.4031 64.1779 82.5705 63.0111 81.4829C61.8442 80.3953 60.0151 80.4647 58.9257 81.6379C57.8363 82.811 57.8991 84.6437 59.066 85.7312ZM59.9512 84.7781C60.5944 85.3776 61.6027 85.3394 62.2032 84.6927C62.8038 84.0459 62.7692 83.0357 62.1259 82.4361C61.4827 81.8366 60.4744 81.8748 59.8738 82.5216C59.2733 83.1683 59.3079 84.1786 59.9512 84.7781Z"
                        fill="#888888"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M40.3638 87.3219C41.5307 88.4094 43.3597 88.3401 44.4491 87.1669C45.5386 85.9938 45.4758 84.1611 44.3089 83.0735C43.1421 81.986 41.313 82.0553 40.2236 83.2285C39.1342 84.4017 39.197 86.2343 40.3638 87.3219ZM41.249 86.3687C41.8922 86.9682 42.9005 86.93 43.5011 86.2832C44.1017 85.6365 44.067 84.6262 43.4238 84.0267C42.7806 83.4272 41.7723 83.4654 41.1717 84.1121C40.5711 84.7589 40.6058 85.7691 41.249 86.3687Z"
                        fill="#888888"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M53.4042 70.2734C54.571 71.3609 56.4001 71.2915 57.4895 70.1184C58.5789 68.9452 58.5161 67.1126 57.3493 66.025C56.1824 64.9374 54.3533 65.0068 53.2639 66.18C52.1745 67.3531 52.2373 69.1858 53.4042 70.2734ZM54.2893 69.3202C54.9326 69.9197 55.9409 69.8814 56.5414 69.2347C57.142 68.588 57.1074 67.5777 56.4641 66.9782C55.8209 66.3787 54.8126 66.4169 54.212 67.0636C53.6115 67.7103 53.6461 68.7206 54.2893 69.3202Z"
                        fill="#888888"
                    />
                </svg>
                <svg
                    className="healthcare-img"
                    width="115"
                    height="151"
                    viewBox="0 0 115 151"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M74.442 51.5241C71.6967 52.2839 68.8644 50.6719 68.1158 47.9235C67.3673 45.1751 68.986 42.3311 71.7313 41.5713C74.4766 40.8115 77.3089 42.4235 78.0575 45.1719C78.806 47.9203 77.1873 50.7643 74.442 51.5241Z"
                        fill="#424242"
                    />
                    <path
                        d="M65.2981 51.6456C64.007 53.2497 61.6665 53.5059 60.0703 52.2178C58.4741 50.9297 58.2267 48.5851 59.5178 46.981C60.8088 45.3769 63.1493 45.1207 64.7455 46.4088C66.3417 47.6969 66.5891 50.0415 65.2981 51.6456Z"
                        fill="#424242"
                    />
                    <path
                        d="M55.0506 56.5308C53.7229 57.9605 51.4938 58.0451 50.0717 56.7196C48.6497 55.3942 48.5731 53.1607 49.9008 51.7309C51.2285 50.3012 53.4577 50.2166 54.8797 51.5421C56.3018 52.8675 56.3783 55.101 55.0506 56.5308Z"
                        fill="#424242"
                    />
                    <path
                        d="M45.9374 62.9762C44.8839 64.1107 43.115 64.1779 41.9865 63.1261C40.8581 62.0743 40.7973 60.3019 41.8509 59.1674C42.9045 58.0328 44.6733 57.9657 45.8018 59.0175C46.9303 60.0693 46.991 61.8416 45.9374 62.9762Z"
                        fill="#424242"
                    />
                    <path
                        d="M37.0175 72.4722C35.6312 72.3004 34.6504 71.0346 34.8267 69.6448C35.003 68.2551 36.2697 67.2677 37.6559 67.4395C39.0421 67.6112 40.0229 68.8771 39.8466 70.2668C39.6703 71.6566 38.4037 72.6439 37.0175 72.4722Z"
                        fill="#424242"
                    />
                    <path
                        d="M35.3384 81.3856C34.7016 82.5046 33.2818 82.8975 32.1674 82.2632C31.053 81.629 30.6659 80.2077 31.3028 79.0887C31.9397 77.9697 33.3594 77.5767 34.4738 78.211C35.5882 78.8453 35.9753 80.2666 35.3384 81.3856Z"
                        fill="#424242"
                    />
                    <path
                        d="M34.0346 92.329C33.4097 93.427 32.0167 93.8125 30.9233 93.1902C29.8298 92.5678 29.45 91.1732 30.0749 90.0753C30.6998 88.9773 32.0928 88.5918 33.1863 89.2142C34.2797 89.8365 34.6595 91.2311 34.0346 92.329Z"
                        fill="#424242"
                    />
                    <path
                        d="M34.6812 103.011C34.2177 103.825 33.1847 104.111 32.3737 103.649C31.5628 103.188 31.2811 102.154 31.7446 101.339C32.208 100.525 33.2411 100.239 34.052 100.701C34.863 101.162 35.1446 102.197 34.6812 103.011Z"
                        fill="#424242"
                    />
                    <path
                        d="M37.2857 113.173C36.956 113.752 36.2209 113.956 35.6438 113.627C35.0668 113.299 34.8663 112.563 35.1961 111.984C35.5259 111.404 36.261 111.201 36.8381 111.529C37.4151 111.858 37.6155 112.593 37.2857 113.173Z"
                        fill="#424242"
                    />
                    <path
                        d="M84.8002 47.5896C82.7011 48.1705 80.5355 46.9379 79.9631 44.8364C79.3908 42.735 80.6284 40.5604 82.7276 39.9794C84.8267 39.3985 86.9923 40.6311 87.5646 42.7325C88.137 44.834 86.8993 47.0086 84.8002 47.5896Z"
                        fill="#424242"
                    />
                    <path
                        d="M96.706 40.6779C95.7545 42.3496 93.6336 42.9367 91.9687 41.9891C90.3038 41.0415 89.7255 38.9181 90.677 37.2464C91.6285 35.5747 93.7495 34.9877 95.4143 35.9352C97.0792 36.8828 97.6575 39.0062 96.706 40.6779Z"
                        fill="#424242"
                    />
                    <path
                        d="M101.908 34.8936C100.327 34.6976 99.2083 33.2538 99.4094 31.6685C99.6105 30.0833 101.055 28.9571 102.637 29.153C104.218 29.3489 105.336 30.7928 105.135 32.378C104.934 33.9632 103.489 35.0895 101.908 34.8936Z"
                        fill="#424242"
                    />
                    <path
                        d="M111.005 24.3787C110.319 25.5826 108.792 26.0054 107.593 25.323C106.394 24.6405 105.978 23.1114 106.663 21.9075C107.348 20.7036 108.876 20.2808 110.074 20.9632C111.273 21.6456 111.69 23.1748 111.005 24.3787Z"
                        fill="#424242"
                    />
                    <path
                        d="M40.8085 146.309C39.938 147.394 38.357 147.57 37.2773 146.701C36.1975 145.833 36.0278 144.249 36.8983 143.164C37.7688 142.079 39.3498 141.903 40.4295 142.772C41.5093 143.64 41.679 145.224 40.8085 146.309Z"
                        fill="#424242"
                    />
                    <path
                        d="M41.8534 135.259C41.0371 136.223 39.5979 136.344 38.6387 135.529C37.6796 134.715 37.5637 133.273 38.38 132.309C39.1962 131.345 40.6354 131.224 41.5946 132.038C42.5537 132.853 42.6696 134.295 41.8534 135.259Z"
                        fill="#424242"
                    />
                    <path
                        d="M40.431 123.643C39.9675 124.457 38.9345 124.743 38.1235 124.281C37.3126 123.82 37.0309 122.786 37.4944 121.971C37.9578 121.157 38.9909 120.871 39.8018 121.333C40.6127 121.794 40.8944 122.829 40.431 123.643Z"
                        fill="#424242"
                    />
                    <path
                        d="M37.9265 117.987C35.0207 118.791 32.0228 117.085 31.2305 114.175C30.4382 111.266 32.1515 108.256 35.0573 107.452C37.9631 106.648 40.961 108.354 41.7534 111.263C42.5457 114.172 40.8323 117.182 37.9265 117.987Z"
                        fill="#424242"
                    />
                    <path
                        d="M7.01476 127.771C9.7024 129.124 12.3621 130.527 15.0127 131.947C16.3408 132.652 17.658 133.377 18.9799 134.091L22.9315 136.265L26.8676 138.466C28.1744 139.208 29.4861 139.942 30.7881 140.695C33.3969 142.191 35.9949 143.704 38.5656 145.268L39.1453 144.222C36.4577 142.87 33.7977 141.468 31.1474 140.047C29.8193 139.341 28.5028 138.617 27.1802 137.902L23.2286 135.729L19.2925 133.528C17.9858 132.785 16.674 132.051 15.372 131.298C12.7632 129.803 10.1652 128.29 7.59455 126.726L7.01476 127.771Z"
                        fill="#424242"
                    />
                    <path
                        d="M15.5958 120.829C17.6786 121.838 19.732 122.9 21.7768 123.978L23.3083 124.789L24.8332 125.612C25.8525 126.157 26.8614 126.719 27.8744 127.274C29.8994 128.388 31.9082 129.529 33.906 130.692C35.9007 131.859 37.8846 133.047 39.8303 134.303L40.4115 133.258C38.3178 132.268 36.2628 131.21 34.2184 130.132C32.1767 129.049 30.1473 127.945 28.1324 126.813C27.1256 126.247 26.1152 125.686 25.1149 125.108L23.611 124.246L22.1137 123.373C20.1197 122.205 18.1332 121.022 16.1772 119.785L15.5958 120.829Z"
                        fill="#424242"
                    />
                    <path
                        d="M25.5232 116.005C26.6923 116.484 27.8252 117.028 28.9523 117.582C30.077 118.141 31.1863 118.726 32.2866 119.329C33.3869 119.932 34.4741 120.556 35.5425 121.216C36.6087 121.879 37.6636 122.563 38.6723 123.33L39.2541 122.286C38.0711 121.831 36.9347 121.295 35.8099 120.737C34.6867 120.176 33.5825 119.58 32.4918 118.962C31.4001 118.344 30.318 117.709 29.2518 117.047C28.1879 116.38 27.1288 115.703 26.1061 114.961L25.5232 116.005Z"
                        fill="#424242"
                    />
                    <path
                        d="M37.017 70.59C39.0041 71.4927 40.9546 72.4599 42.894 73.4474C44.8352 74.4318 46.7563 75.4515 48.6714 76.4829C49.6301 76.9965 50.5783 77.5287 51.5319 78.0512C52.4787 78.5858 53.4282 79.1156 54.3681 79.6623C56.2515 80.7492 58.1203 81.863 59.9492 83.0469L60.642 81.812C58.6489 80.9198 56.6953 79.9583 54.7572 78.9683C53.7866 78.4767 52.8247 77.9687 51.86 77.4655C50.9027 76.9494 49.9398 76.4441 48.9876 75.9192C47.0803 74.8741 45.1794 73.8178 43.2987 72.726C41.4162 71.6375 39.5451 70.5278 37.7098 69.3551L37.017 70.59Z"
                        fill="#424242"
                    />
                    <path
                        d="M44.3586 87.004C46.1658 87.8969 47.9504 88.8303 49.7263 89.779L50.3929 90.1339L51.0569 90.4933L52.3848 91.2125L55.0294 92.6704L57.6626 94.1495C58.5367 94.6492 59.4139 95.1435 60.2835 95.651C62.0266 96.6595 63.7619 97.6815 65.4755 98.7429L65.8262 98.1103C64.0202 97.2154 62.2363 96.2807 60.4601 95.3325C59.5701 94.8618 58.6873 94.3774 57.8017 93.8989L55.1558 92.4431L52.5215 90.9661L51.2096 90.2178L50.554 89.8436L49.901 89.4648C48.1577 88.4568 46.4228 87.4339 44.7103 86.3698L44.3586 87.004Z"
                        fill="#424242"
                    />
                    <path
                        d="M31.7648 91.7244C33.9229 92.7573 36.0468 93.8517 38.1587 94.9673C39.2181 95.5189 40.264 96.0944 41.3168 96.6576L44.4555 98.3837L47.5756 100.142C48.6102 100.739 49.6495 101.326 50.6775 101.935C52.7389 103.141 54.7887 104.369 56.8054 105.656L57.3865 104.611C55.2292 103.577 53.1053 102.483 50.9938 101.366C49.9348 100.814 48.8877 100.241 47.835 99.6757L44.6951 97.9517L41.5739 96.195C40.5397 95.5973 39.4996 95.0117 38.472 94.4025C36.4106 93.1968 34.3608 91.9689 32.3453 90.6793L31.7648 91.7244Z"
                        fill="#424242"
                    />
                    <path
                        d="M32.9222 102.697C34.1598 103.211 35.3602 103.793 36.5513 104.39C37.74 104.992 38.9155 105.619 40.0778 106.269C41.2405 106.918 42.3909 107.589 43.5276 108.284C44.6624 108.984 45.7879 109.698 46.8772 110.479L47.4597 109.435C46.2229 108.92 45.0233 108.337 43.8318 107.74C42.6431 107.138 41.4676 106.511 40.3041 105.863C39.1402 105.216 37.9895 104.546 36.8531 103.851C35.7187 103.152 34.5928 102.436 33.5047 101.653L32.9222 102.697Z"
                        fill="#424242"
                    />
                    <path
                        d="M57.3981 69.955C59.0505 70.6316 60.6587 71.3866 62.2533 72.1655C63.8498 72.9411 65.4218 73.7606 66.9863 74.5933C67.77 75.0066 68.5405 75.4441 69.3185 75.8684L70.4726 76.5274C70.8567 76.7482 71.2426 76.9657 71.6216 77.1956C73.148 78.096 74.6553 79.0302 76.1155 80.0489L76.8251 78.7876C75.1653 78.1242 73.5533 77.3758 71.9606 76.5935C71.5596 76.4024 71.1656 76.2 70.7698 75.9998L69.5842 75.3969C68.802 74.9801 68.0132 74.5758 67.237 74.1482C65.6819 73.2991 64.1343 72.4357 62.6116 71.5287C61.0866 70.6258 59.576 69.6973 58.1087 68.6921L57.3981 69.955Z"
                        fill="#424242"
                    />
                    <path
                        d="M52.1901 54.6574C54.349 55.6888 56.474 56.7812 58.5894 57.8907L60.1744 58.7262L61.7517 59.5753C62.8054 60.1371 63.8496 60.717 64.8959 61.2918C66.9914 62.4377 69.0637 63.6244 71.1255 64.8294L72.6667 65.7436L74.1994 66.6727C75.2169 67.2995 76.2332 67.9284 77.2317 68.5904L77.8128 67.5457C76.7246 67.0462 75.654 66.5142 74.5845 65.9803L72.9873 65.1676L71.3987 64.34C69.2876 63.2231 67.1863 62.0874 65.1078 60.9116C64.0677 60.326 63.0242 59.7447 61.9912 59.145L60.4387 58.2524L58.8933 57.3457C56.835 56.1328 54.7871 54.9032 52.772 53.6131L52.1901 54.6574Z"
                        fill="#424242"
                    />
                    <path
                        d="M62.1179 49.8331C62.74 50.0845 63.3486 50.3597 63.9555 50.6394C64.5617 50.9186 65.1632 51.2078 65.7594 51.5048C66.954 52.0965 68.1325 52.7177 69.3 53.357C70.4689 53.9953 71.6235 54.6587 72.7564 55.3603C73.8876 56.0663 75.0054 56.7944 76.0726 57.6162L76.6551 56.5722C75.3958 56.096 74.188 55.5275 72.9931 54.9365C71.8008 54.3408 70.6292 53.7073 69.4719 53.0486C68.3143 52.3906 67.1666 51.7149 66.035 51.0096C65.4688 50.6584 64.9065 50.2988 64.3507 49.9293C63.7938 49.5601 63.2403 49.1865 62.6994 48.7894L62.1179 49.8331Z"
                        fill="#424242"
                    />
                    <path
                        d="M70.3238 36.9838C71.5068 37.4382 72.6402 37.9797 73.7638 38.54C74.8851 39.1043 75.9861 39.7058 77.0763 40.3263C78.1673 40.9454 79.2478 41.5829 80.3164 42.2415C81.3822 42.9049 82.4436 43.5775 83.4726 44.3073L84.0544 43.263C82.892 42.7725 81.7618 42.2238 80.6362 41.667C79.5134 41.1053 78.403 40.522 77.3019 39.9205C76.2001 39.3204 75.1102 38.7009 74.0394 38.0447C72.9716 37.3847 71.9141 36.7053 70.905 35.9391L70.3238 36.9838Z"
                        fill="#424242"
                    />
                    <path
                        d="M69.166 26.012C71.2504 27.0182 73.3037 28.0806 75.3451 29.1641C76.3682 29.7023 77.381 30.2571 78.3991 30.8042C79.4106 31.3628 80.4253 31.9142 81.4337 32.4783C83.4506 33.6064 85.4624 34.7433 87.4579 35.9088C89.4546 37.0723 91.4427 38.2525 93.4013 39.4844L93.9825 38.4397C91.9023 37.426 89.8505 36.3609 87.8084 35.2787C85.7648 34.1993 83.7378 33.0907 81.7148 31.9735C80.7032 31.4148 79.6987 30.844 78.6903 30.2799C77.6884 29.7043 76.6823 29.1362 75.6855 28.5517C73.6877 27.3903 71.7015 26.2067 69.7471 24.9673L69.166 26.012Z"
                        fill="#424242"
                    />
                    <path
                        d="M70.4329 15.0466C73.1384 16.3676 75.8074 17.7542 78.4639 19.1627C79.7952 19.8609 81.1139 20.5827 82.4385 21.2942L86.3931 23.4623L90.3277 25.6656L92.2861 26.783L93.2651 27.342L94.2398 27.9085C96.8423 29.415 99.4309 30.946 101.983 32.5434L102.562 31.4979C99.8558 30.179 97.1868 28.7941 94.5299 27.3845L93.5327 26.8575L92.5398 26.323L90.5543 25.2533L86.6012 23.0825L82.6682 20.8764C81.3634 20.1302 80.0531 19.3935 78.755 18.6339C76.1529 17.1267 73.5635 15.5971 71.0106 14.0017L70.4329 15.0466Z"
                        fill="#424242"
                    />
                    <path
                        d="M74.4508 4.76421C77.3561 6.22417 80.2309 7.73787 83.0955 9.27102C85.962 10.8008 88.8103 12.3625 91.6543 13.9317L95.9097 16.3059C97.3207 17.1099 98.7389 17.9027 100.145 18.7156C102.963 20.3321 105.77 21.9701 108.543 23.6659L109.123 22.6205C106.215 21.1663 103.339 19.6536 100.475 18.1198C99.04 17.358 97.617 16.5751 96.187 15.8044L91.9192 13.4537C89.0811 11.8725 86.2479 10.2843 83.4322 8.66368C80.6145 7.04647 77.8067 5.41051 75.0302 3.71943L74.4508 4.76421Z"
                        fill="#424242"
                    />
                    <path
                        d="M77.2496 5.66895C76.4577 7.06036 74.6924 7.54895 73.3067 6.76025C71.921 5.97156 71.4396 4.20423 72.2316 2.81282C73.0235 1.42142 74.7888 0.932821 76.1745 1.72152C77.5602 2.51022 78.0416 4.27754 77.2496 5.66895Z"
                        fill="#424242"
                    />
                    <path
                        d="M72.5534 16.2335C71.6069 17.2527 70.0178 17.313 69.004 16.3681C67.9902 15.4232 67.9357 13.831 68.8822 12.8117C69.8287 11.7925 71.4178 11.7322 72.4316 12.6771C73.4454 13.622 73.4999 15.2142 72.5534 16.2335Z"
                        fill="#424242"
                    />
                    <path
                        d="M71.1183 27.0407C70.2588 27.9662 68.8159 28.0209 67.8953 27.1629C66.9748 26.305 66.9253 24.8592 67.7847 23.9336C68.6441 23.0081 70.0871 22.9534 71.0076 23.8114C71.9282 24.6694 71.9777 26.1152 71.1183 27.0407Z"
                        fill="#424242"
                    />
                    <path
                        d="M72.0828 37.2977C71.6194 38.1119 70.5863 38.3978 69.7754 37.9363C68.9644 37.4747 68.6828 36.4405 69.1462 35.6263C69.6097 34.812 70.6427 34.5261 71.4536 34.9876C72.2646 35.4492 72.5462 36.4834 72.0828 37.2977Z"
                        fill="#424242"
                    />
                    <path
                        d="M29.0974 117.352C28.061 119.173 25.7508 119.813 23.9374 118.781C22.1239 117.749 21.494 115.436 22.5304 113.615C23.5668 111.794 25.877 111.154 27.6905 112.187C29.5039 113.219 30.1338 115.532 29.0974 117.352Z"
                        fill="#424242"
                    />
                    <path
                        d="M19.0196 122.091C18.0305 123.828 15.8256 124.439 14.0948 123.454C12.3641 122.469 11.7629 120.261 12.7521 118.523C13.7412 116.785 15.9461 116.175 17.6768 117.16C19.4075 118.145 20.0087 120.353 19.0196 122.091Z"
                        fill="#424242"
                    />
                    <path
                        d="M9.3842 129.173C8.32276 130.325 6.53377 130.399 5.38839 129.34C4.243 128.28 4.17495 126.488 5.23639 125.336C6.29783 124.184 8.08681 124.11 9.2322 125.17C10.3776 126.229 10.4456 128.022 9.3842 129.173Z"
                        fill="#424242"
                    />
                    <path
                        d="M48.1583 113.589C46.1545 114.143 44.0871 112.967 43.5407 110.96C42.9943 108.954 44.1758 106.878 46.1797 106.324C48.1836 105.769 50.251 106.946 50.7974 108.952C51.3438 110.958 50.1622 113.034 48.1583 113.589Z"
                        fill="#424242"
                    />
                    <path
                        d="M59.994 106.784C59.0793 108.391 57.0403 108.955 55.4398 108.044C53.8393 107.133 53.2834 105.092 54.1981 103.485C55.1128 101.878 57.1517 101.313 58.7522 102.224C60.3528 103.135 60.9087 105.176 59.994 106.784Z"
                        fill="#424242"
                    />
                    <path
                        d="M65.3196 100.997C63.776 100.806 62.6838 99.3966 62.8801 97.8491C63.0765 96.3016 64.4869 95.2022 66.0305 95.3934C67.574 95.5847 68.6662 96.9942 68.4699 98.5417C68.2736 100.089 66.8631 101.189 65.3196 100.997Z"
                        fill="#424242"
                    />
                    <path
                        d="M72.9137 91.7917C71.5469 92.17 70.1367 91.3674 69.7641 89.999C69.3914 88.6307 70.1973 87.2147 71.5641 86.8364C72.931 86.4581 74.3411 87.2607 74.7138 88.6291C75.0865 89.9975 74.2806 91.4134 72.9137 91.7917Z"
                        fill="#424242"
                    />
                    <path
                        d="M78.4371 80.273C77.7489 81.4822 76.2148 81.9068 75.0106 81.2214C73.8063 80.536 73.388 79.0001 74.0763 77.791C74.7645 76.5818 76.2986 76.1572 77.5028 76.8426C78.707 77.528 79.1253 79.0638 78.4371 80.273Z"
                        fill="#424242"
                    />
                    <path
                        d="M79.5023 69.1937C78.8774 70.2917 77.4844 70.6772 76.391 70.0548C75.2975 69.4325 74.9177 68.0379 75.5426 66.94C76.1675 65.842 77.5605 65.4565 78.654 66.0788C79.7474 66.7012 80.1273 68.0958 79.5023 69.1937Z"
                        fill="#424242"
                    />
                    <path
                        d="M77.8326 57.9297C77.3692 58.7439 76.3361 59.0299 75.5252 58.5683C74.7143 58.1068 74.4326 57.0725 74.896 56.2583C75.3595 55.444 76.3925 55.1581 77.2035 55.6196C78.0144 56.0812 78.2961 57.1154 77.8326 57.9297Z"
                        fill="#424242"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M59.8688 85.7311C61.0356 86.8187 62.8647 86.7493 63.9541 85.5762C65.0435 84.403 64.9807 82.5703 63.8139 81.4828C62.647 80.3952 60.8179 80.4646 59.7285 81.6377C58.6391 82.8109 58.7019 84.6436 59.8688 85.7311ZM60.7539 84.778C61.3972 85.3775 62.4055 85.3393 63.006 84.6925C63.6066 84.0458 63.572 83.0355 62.9287 82.436C62.2855 81.8365 61.2772 81.8747 60.6766 82.5214C60.0761 83.1682 60.1107 84.1784 60.7539 84.778Z"
                        fill="#424242"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M41.1665 87.322C42.3334 88.4095 44.1625 88.3401 45.2519 87.167C46.3413 85.9938 46.2785 84.1612 45.1116 83.0736C43.9448 81.986 42.1157 82.0554 41.0263 83.2286C39.9369 84.4017 39.9997 86.2344 41.1665 87.322ZM42.0517 86.3688C42.695 86.9683 43.7033 86.93 44.3038 86.2833C44.9044 85.6366 44.8698 84.6263 44.2265 84.0268C43.5833 83.4272 42.575 83.4655 41.9744 84.1122C41.3739 84.7589 41.4085 85.7692 42.0517 86.3688Z"
                        fill="#424242"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M54.207 70.2734C55.3738 71.3609 57.2029 71.2915 58.2923 70.1184C59.3817 68.9452 59.3189 67.1126 58.1521 66.025C56.9852 64.9374 55.1561 65.0068 54.0667 66.18C52.9773 67.3531 53.0401 69.1858 54.207 70.2734ZM55.0921 69.3202C55.7354 69.9197 56.7437 69.8814 57.3442 69.2347C57.9448 68.588 57.9102 67.5777 57.2669 66.9782C56.6237 66.3787 55.6154 66.4169 55.0148 67.0636C54.4143 67.7103 54.4489 68.7206 55.0921 69.3202Z"
                        fill="#424242"
                    />
                </svg>
            </div>
            <ul className="solutions-item-list">
                <li className="solutions-item-list-item">E-health</li>
                <li className="solutions-item-list-item">
                    Product Management
                </li>
                <li className="solutions-item-list-item">
                    Pharma and Biotech
                </li>
            </ul>
        </div>
    )
}
