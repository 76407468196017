import React from "react";
import {ReactComponent as HealthcareChipSVG} from "../../../assets/img/splash/healthcare-chip.svg";
// import {ReactComponent as HealthcareChipOuterSVG} from "../assets/img/splash/healthcare-chip-outer.svg";

function HealthcareChip() {
  return (
    <div>
      <HealthcareChipSVG className="healthcare-chip" />
      {/*<HealthcareChipOuterSVG className="healthcare-chip-outer" />*/}
      <div className="healthcare-chip-header">Healthcare & Life Sciences</div>
    </div>
  );
}

export default HealthcareChip;
