import React from "react";
import {ReactComponent as HealthcareBiotechChipSVG} from "../../../assets/img/splash/healthcare-biotech-chip.svg";
// import {ReactComponent as HealthcareBiotechChipOuterSVG} from "../assets/img/splash/healthcare-biotech-chip-outer.svg";

function HealthcareBiotechChip() {
  return (
    <div>
      <HealthcareBiotechChipSVG className="healthcare-biotech-chip" />
      {/*<HealthcareBiotechChipOuterSVG className="healthcare-biotech-chip-outer" />*/}
      <div className="healthcare-biotech-chip-header">Pharma and Biotech</div>
    </div>
  );
}

export default HealthcareBiotechChip;
