import React from "react";
import {ReactComponent as PharmaPMChipSVG} from "../../../assets/img/splash/healthcare-pm-chip.svg";
// import {ReactComponent as PharmaPMChipOuterSVG} from "../assets/img/splash/healthcare-pm-chip-outer.svg";

function HealthcarePMChip() {
  return (
    <div>
      <PharmaPMChipSVG className="healthcare-pm-chip" />
      {/*<PharmaPMChipOuterSVG className="healthcare-pm-chip-outer" />*/}
      <div className="healthcare-pm-chip-header">Product Management</div>
    </div>
  );
}

export default HealthcarePMChip;
