import React from "react";
import {ReactComponent as DataAIChipSVG} from "../../../assets/img/splash/data-ai-chip.svg";
// import {ReactComponent as DataAIChipOuterSVG} from "../assets/img/splash/data-ai-chip-outer.svg";

function DataScienceAIChip() {
  return (
    <div>
      <DataAIChipSVG className="data-ai-chip" />
      {/*<DataAIChipOuterSVG className="data-ai-chip-outer" />*/}
      <div className="data-ai-chip-header">Artificial intelligence</div>
    </div>
  );
}

export default DataScienceAIChip;
