import React from "react";
import {ReactComponent as DataChipSVG} from "../../../assets/img/splash/data-chip.svg";
// import {ReactComponent as DataChipOuterSVG} from "../assets/img/splash/data-chip-outer.svg";

function DataChip() {
  return (
    <div>
      <DataChipSVG className="data-chip" />
      {/*<DataChipOuterSVG className="data-chip-outer" />*/}
      <div className="data-chip-header">Data Science</div>
    </div>
  );
}

export default DataChip;
